<template>
  <div id="profiles" class="d-flex ">
    <!--begin::Profiles-->
    <b-dropdown
      class="dropdown topbar-item"
      toggle-class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg"
      menu-class="dropdown-menu p-0 m-0 dropdown-menu-sm dropdown-menu-right"
      offset=""
      right
      :no-caret="true"
      v-if="profilesType.length > 1"
    >
      <template slot="button-content">
        <b-button
          tag="div"
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg"
        >
          <span class="svg-icon svg-icon-xl svg-icon-dark-75">
            <!--begin::Svg Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-if="profile == 'user'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="12" cy="12" r="10"/><path d="M12 11a2 2 0 110-4 2 2 0 010 4zm-5 5.5c.216-2.983 2.368-4.5 4.99-4.5 2.66 0 4.846 1.433 5.009 4.5.006.122 0 .5-.418.5H7.404c-.14 0-.415-.338-.404-.5z" fill="#000" opacity=".3"/></g></svg>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="profile == 'controller'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="2" y="3" width="20" height="18" rx="2"/><path d="M9.949 13.316A1 1 0 019 14H5a1 1 0 010-2h3.28l1.771-5.316c.317-.95 1.676-.897 1.92.073l1.844 7.378.79-1.582A1 1 0 0115.5 12H19a1 1 0 010 2h-2.882l-1.724 3.447c-.415.83-1.639.696-1.864-.204l-1.667-6.669-.914 2.742z" fill="#000" fill-rule="nonzero"/><circle fill="#000" opacity=".3" cx="19" cy="6" r="1"/></g></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-else-if="profile == 'admin'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18.622 9.75h.128a2.25 2.25 0 110 4.5h-.065a.488.488 0 00-.446.295.488.488 0 00.089.54l.045.044a2.25 2.25 0 11-3.183 3.184l-.04-.04a.49.49 0 00-.545-.094.486.486 0 00-.295.444v.127a2.25 2.25 0 11-4.5 0 .524.524 0 00-.363-.514.485.485 0 00-.532.092l-.044.045a2.25 2.25 0 11-3.184-3.183l.04-.04a.49.49 0 00.094-.545.486.486 0 00-.443-.295H5.25a2.25 2.25 0 110-4.5.524.524 0 00.514-.363.485.485 0 00-.092-.532l-.045-.044A2.25 2.25 0 118.81 5.687l.04.04c.142.139.355.177.537.097l.108-.023a.486.486 0 00.255-.423V5.25a2.25 2.25 0 114.5 0v.065c0 .194.117.37.303.449.182.08.395.042.532-.092l.044-.045a2.25 2.25 0 113.184 3.183l-.04.04a.488.488 0 00-.097.537l.023.108a.486.486 0 00.423.255z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" fill="#000"/></g></svg>
            <!--end::Svg Icon-->
          </span>
        </b-button>
      </template>
      <b-dropdown-item
        v-if="isAdmin() && profile !== 'admin'"
        @click="goToAdmin"
      >
        <span class="btn btn-icon">
          <span class="svg-icon svg-icon-xl svg-icon-dark-75">
            <!--begin::Svg Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18.622 9.75h.128a2.25 2.25 0 110 4.5h-.065a.488.488 0 00-.446.295.488.488 0 00.089.54l.045.044a2.25 2.25 0 11-3.183 3.184l-.04-.04a.49.49 0 00-.545-.094.486.486 0 00-.295.444v.127a2.25 2.25 0 11-4.5 0 .524.524 0 00-.363-.514.485.485 0 00-.532.092l-.044.045a2.25 2.25 0 11-3.184-3.183l.04-.04a.49.49 0 00.094-.545.486.486 0 00-.443-.295H5.25a2.25 2.25 0 110-4.5.524.524 0 00.514-.363.485.485 0 00-.092-.532l-.045-.044A2.25 2.25 0 118.81 5.687l.04.04c.142.139.355.177.537.097l.108-.023a.486.486 0 00.255-.423V5.25a2.25 2.25 0 114.5 0v.065c0 .194.117.37.303.449.182.08.395.042.532-.092l.044-.045a2.25 2.25 0 113.184 3.183l-.04.04a.488.488 0 00-.097.537l.023.108a.486.486 0 00.423.255z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" fill="#000"/></g></svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="navi-text pt-3">
          {{ $t("menu.toggle.admin") }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isController() && profile !== 'controller'"
        @click="goToController"
      >
        <span class="btn btn-icon">
          <span class="svg-icon svg-icon-xl svg-icon-dark-75">
            <!--begin::Svg Icon -->
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:ssrc="'@/assets/images/icons/diagnostics.svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="2" y="3" width="20" height="18" rx="2"/><path d="M9.949 13.316A1 1 0 019 14H5a1 1 0 010-2h3.28l1.771-5.316c.317-.95 1.676-.897 1.92.073l1.844 7.378.79-1.582A1 1 0 0115.5 12H19a1 1 0 010 2h-2.882l-1.724 3.447c-.415.83-1.639.696-1.864-.204l-1.667-6.669-.914 2.742z" fill="#000" fill-rule="nonzero"/><circle fill="#000" opacity=".3" cx="19" cy="6" r="1"/></g></svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="navi-text pt-3">
          {{ $t("menu.toggle.controller") }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isUser() && profile !== 'user'"
        @click="goToEmployee"
      >
        <span class="btn btn-icon">
          <span class="svg-icon svg-icon-xl svg-icon-dark-75">
            <!--begin::Svg Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="12" cy="12" r="10"/><path d="M12 11a2 2 0 110-4 2 2 0 010 4zm-5 5.5c.216-2.983 2.368-4.5 4.99-4.5 2.66 0 4.846 1.433 5.009 4.5.006.122 0 .5-.418.5H7.404c-.14 0-.415-.338-.404-.5z" fill="#000" opacity=".3"/></g></svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="navi-text pt-3">
          {{ $t("menu.toggle.employee") }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <!--end::Profiles-->
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import { removeProfileData, checkCompanies } from "@/utils/helpers";

export default {
  name: "ProfilesDropdown",
  props: {
    profile: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      profilesType: []
    };
  },
  methods: {
    ...mapActions("Auth", ["profiles"]),
    isAdmin() {
      return _.includes(this.profilesType, "ADMIN");
    },
    isUser() {
      return _.includes(this.profilesType, "USER");
    },
    isController() {
      return _.includes(this.profilesType, "CONTROLLER");
    },
    goToEmployee() {
      localStorage.removeItem("managedCompanyId");
      localStorage.removeItem("companySelected");
      this.$store.dispatch("Admin/reset", {}, { root: true });
      this.$store.dispatch("Controller/reset", {}, { root: true });
      this.$store.dispatch("Admin/resetCompanies", {}, { root: true });
      this.$store.dispatch("Controller/resetCompanies", {}, { root: true });
      removeProfileData();
      this.$router.push({ name: "Dashboard" });
    },
    goToController() {
      const companiesController = localStorage
        .getItem("controllerProfiles")
        .split(",");
      if (!_.includes(this.$route.path, "controller")) {
        this.$store.dispatch("Admin/reset", {}, { root: true });
        this.$store.dispatch("Admin/resetCompanies", {}, { root: true });
        this.$store.dispatch("Controller/setCompany", false);
        checkCompanies(companiesController);
        removeProfileData();
        companiesController.length > 1
          ? this.$router.push({ name: "Controller" })
          : this.$router.push({ name: "ControllerDashboard" });
      }
    },
    goToAdmin() {
      const companiesAdmin = localStorage.getItem("adminProfiles").split(",");
      if (!_.includes(this.$route.path, "admin")) {
        this.$store.dispatch("Controller/reset", {}, { root: true });
        this.$store.dispatch("Controller/resetCompanies", {}, { root: true });
        this.$store.dispatch("Admin/setCompany", false);
        checkCompanies(companiesAdmin);
        removeProfileData();
        companiesAdmin.length > 1
          ? this.$router.push({ name: "Admin" })
          : this.$router.push({ name: "Company" });
      }
    }
  },
  mounted() {
    this.profilesType = localStorage.getItem("profiles").split(",");
  }
};
</script>
