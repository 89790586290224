<template>
  <!-- begin::User Panel-->
  <div
    :class="`offcanvas offcanvas-${side} p-10 offcanvas-on`"
    style="z-index: 10000;"
  >
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h3 class="font-weight-bold m-0">
        {{ $t("menu.userPanel.profile") }}
      </h3>
      <b-button
        tag="a"
        @click="$emit('user-panel-closed')"
        class="btn btn-xs btn-icon btn-light btn-hover-primary"
      >
        <i class="ki ki-close icon-xs text-muted"></i>
      </b-button>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5">
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <div class="symbol-label">
            <avatar size="lg" :avatar="avatar" />
          </div>
          <i class="-badge bg-success"></i>
        </div>
        <div class="d-flex flex-column">
          <a
            href="#"
            class="text-left font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >{{ fullname }}</a
          >
          <div class="text-muted mt-1 text-left" v-html="archetype" />
          <div class="navi mt-2 text-left">
            <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M21 12.083A6 6 0 0014.083 19H5a2 2 0 01-2-2V8a2 2 0 012-2h14a2 2 0 012 2v4.083zM18.144 7.84L12 11.148 5.856 7.84a.75.75 0 10-.712 1.32l6.5 3.5c.222.12.49.12.712 0l6.5-3.5a.75.75 0 00-.712-1.32z" fill="#000"/><circle fill="#000" opacity=".3" cx="19.5" cy="17.5" r="2.5"/></g></svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span
                  id="user-panel-email"
                  class="navi-text text-muted text-hover-primary text-left"
                  style="font-size: 0.8rem"
                  >{{ user.email }}</span
                >
              </span>
            </a>
            <b-button
              id="exit"
              @click="logout()"
              variant="light-default"
              class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5"
              >{{ $t("menu.userPanel.buttons.exit") }}</b-button
            >
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Separator-->
      <div
        class="separator separator-solid mt-8 mb-5"
        v-if="profileType === 'CONTROLLER'"
      />
      <!--end::Separator-->

      <!--begin::Controller-->
      <div
        class="d-flex align-items-center pb-9"
        v-if="profileType === 'CONTROLLER'"
      >
        <div
          class="d-flex flex-column flex-grow-1 text-left font-size-h4 text-dark-75 font-weight-bolder"
        >
          {{ company.name }}
        </div>
      </div>
      <div
        class="d-flex align-items-center pb-9"
        v-if="profileType === 'CONTROLLER'"
      >
        <!--begin::Symbol-->
        <div class="symbol symbol-45 symbol-light mr-4">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-2x svg-icon-dark-50">
              <simple-svg :src="require('@/assets/images/icons/group.svg')" />
            </span>
          </span>
        </div>
        <!--end::Symbol-->
        <!--begin::Text-->
        <div class="d-flex flex-column flex-grow-1 text-left">
          <router-link
            :to="{ name: 'Users' }"
            class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"
          >
            {{ $t("menu.users.title") }}
          </router-link>
          <span class="text-muted font-weight-bold">{{
            $t("menu.users.description")
          }}</span>
        </div>
        <!--end::Text-->
        <!--begin::label-->
        <span
          class="font-weight-bolder label label-xl label-light-success label-inline px-3 py-5 min-w-45px"
          >{{ company.peopleCount }}</span
        >
        <!--end::label-->
      </div>
      <div
        class="d-flex align-items-center pb-9"
        v-if="profileType === 'CONTROLLER'"
      >
        <!--begin::Symbol-->
        <div class="symbol symbol-45 symbol-light mr-4">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-2x svg-icon-dark-50">
              <simple-svg :src="require('@/assets/images/icons/library.svg')" />
            </span>
          </span>
        </div>
        <!--end::Symbol-->
        <!--begin::Text-->
        <div class="d-flex flex-column flex-grow-1 text-left">
          <router-link
            :to="{ name: 'Departments' }"
            class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"
          >
            {{ $t("menu.departments.title") }}
          </router-link>
          <span class="text-muted font-weight-bold">{{
            $t("menu.departments.description")
          }}</span>
        </div>
        <!--end::Text-->
        <!--begin::label-->
        <span
          class="font-weight-bolder label label-xl label-light-success label-inline px-3 py-5 min-w-45px"
          >{{ company.departmentsCount }}</span
        >
        <!--end::label-->
      </div>
      <!--begin::Separator-->
      <div class="separator separator-solid mt-8 mb-5"></div>
      <!--end::Separator-->

      <!--begin::Nav-->
      <div class="navi navi-spacer-x-0 p-0">
        <!--begin::Item-->
        <router-link
          :to="{ name: 'Account', params: { option: 'password' } }"
          class="navi-item"
          v-if="false"
        >
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-success">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Notification2.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M2.56 10.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm12 0l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121z" fill="#000" opacity=".3"/><path d="M8.56 16.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm0-12l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0L8.561 6.803a1.5 1.5 0 010-2.121z" fill="#000"/></g></svg>
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <div class="navi-text text-left">
              <div class="font-weight-bold">
                {{ $t("menu.userPanel.account.title") }}
              </div>
              <div class="text-muted">
                {{ $t("menu.userPanel.account.subtitle") }}
                <!-- <span
                  class="label label-light-danger label-inline font-weight-bold"
                  >update</span
                > -->
              </div>
            </div>
          </div>
        </router-link>
        <!--end:Item-->
        <!--begin::Item-->
        <router-link
          :to="{ name: 'Account', params: { option: 'password', to: to } }"
          class="navi-item"
          v-if="federated !== 'true'"
        >
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-success">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Notification2.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 4l7.631-1.43a2 2 0 01.738 0L20 4v9.283a8.51 8.51 0 01-4 7.217l-3.47 2.169a1 1 0 01-1.06 0L8 20.5a8.51 8.51 0 01-4-7.217V4z" fill="#000" opacity=".3"/><path d="M12 11a2 2 0 110-4 2 2 0 010 4zM7 16.5c.216-2.983 2.368-4.5 4.99-4.5 2.66 0 4.846 1.433 5.009 4.5.006.122 0 .5-.418.5H7.404c-.14 0-.415-.338-.404-.5z" fill="#000" opacity=".3"/></g></svg>
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <div class="navi-text text-left">
              <div class="font-weight-bold">
                {{ $t("menu.userPanel.password.title") }}
              </div>
              <div class="text-muted">
                {{ $t("menu.userPanel.password.subtitle") }}
                <!-- <span
                  class="label label-light-danger label-inline font-weight-bold"
                  >update</span
                > -->
              </div>
            </div>
          </div>
        </router-link>
        <!--end:Item-->
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Content-->
  </div>
  <!-- end::User Panel-->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SimpleSVG } from "vue-simple-svg";

import Avatar from "@/components/profile/Avatar.component";

export default {
  name: "UserPanel",
  data() {
    return {
      federated: localStorage.getItem("federated"),
      profileType: localStorage.getItem("profile")
    };
  },
  components: {
    Avatar,
    "simple-svg": SimpleSVG
  },
  props: {
    side: {
      type: String,
      default: "right",
      required: false
    },
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("User", [
      "user",
      "fullname",
      "archetype",
      "avatar",
      "company"
    ])
  },
  methods: {
    ...mapActions("Auth", ["logout"])
  }
};
</script>
